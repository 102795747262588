import { GlideIcon, useForceUpdate } from "@glide/common";
import { getLocalizedString } from "@glide/localization";
import { massageImageUrl } from "@glide/common-core/dist/js/components/portable-renderers";
import type {
    WireKanbanColumn,
    WireKanbanComponent,
    WireKanbanItem,
} from "@glide/fluent-components/dist/js/base-components";
import type { WireBackendInterface } from "@glide/hydrated-ui";
import { ignore, isDefined, isEmptyOrUndefinedish } from "@glide/support";
import { type Unbound, UnboundVal } from "@glide/computation-model-types";
import {
    type WireEditableValue,
    APP_MODAL_ROOT,
    UIButtonAppearance,
    UIStyleVariant,
    ValueChangeSource,
} from "@glide/wire";
import { panic } from "@glideapps/ts-necessities";
import classnames from "classnames";
import * as React from "react";
import type { DraggableChildrenFn, DropResult, DroppableProvided } from "react-beautiful-dnd";
import tw from "twin.macro";

import { usePreventDragBack } from "../../chrome/content/lib/prevent-drag-back";
import GrowingEntryView from "../../components/growing-entry/growing-entry-view";
import { Img } from "../../components/img/img";
import { css } from "@glide/common-components";
import { extractActions, runActionAndHandleURL } from "../../wire-lib";
import {
    WireListContainer,
    useDynamicFilter,
    useMultipleDynamicFilters,
    useSearchBar,
} from "../wire-list-container/wire-list-container";
import { WireMenuButton } from "../wire-menu-button/wire-menu-button";
import type { WireRenderer } from "../wire-renderer";
import { lazyLoadWithSequence } from "../../utils/sequenced-lazy-load";

interface WireKanbanCardEditorProps {
    readonly item: WireEditableValue<string> | Unbound;
    readonly value: string;
    readonly onItemChange: (newVal: string) => void;
    readonly isTitle: boolean;
}

const WireKanbanCardEditor: React.VFC<WireKanbanCardEditorProps> = p => {
    const { item, onItemChange, value, isTitle } = p;

    const [edit, setEdit] = React.useState(false);

    const keyDown = React.useCallback((e: React.KeyboardEvent) => {
        if ((e.key === "Enter" && !e.shiftKey) || e.key === "Escape") {
            setEdit(false);
        }
    }, []);

    return (
        <>
            {item !== UnboundVal && !edit && (
                <div
                    className={isTitle ? "title" : "desc"}
                    tw="flex"
                    style={item?.onChangeToken === undefined ? { pointerEvents: "none" } : undefined}
                    onClick={e => {
                        if (item?.onChangeToken === undefined) return;
                        e.stopPropagation();
                        e.preventDefault();
                        setEdit(true);
                    }}>
                    <div
                        css={css`
                            .title & {
                                ${tw`line-height[21px] text-base font-semibold text-text-base`}
                            }

                            .desc & {
                                ${tw`text-sm text-text-pale`}
                            }
                        `}
                        tw="[min-height:21px] whitespace-pre-wrap overflow-wrap[anywhere] rounded-sm cursor-text
                            transition page-hover:(bg-n100 [box-shadow:0 0 0 2px var(--gv-n100)])">
                        {item?.displayValue ?? item?.value}
                    </div>
                </div>
            )}
            {item !== UnboundVal && edit && (
                <GrowingEntryView
                    className={isTitle ? "title" : "desc"}
                    css={css`
                        &.title {
                            ${tw`[min-height:21px] line-height[21px] text-base font-semibold text-text-base`}
                        }

                        &.desc {
                            ${tw`text-sm text-text-pale`}
                        }
                    `}
                    tw="all-child:(font-family[unset]! font-weight[unset]! font-size[unset]! line-height[unset]!) w-full"
                    value={value}
                    autoFocus={true}
                    onChange={e => onItemChange(e.target.value)}
                    onClick={e => e.stopPropagation()}
                    onBlur={() => setEdit(false)}
                    onFocus={e => {
                        e.target.setSelectionRange(0, 100000, "forward");
                    }}
                    onKeyDown={keyDown}
                />
            )}
        </>
    );
};

interface CardProps {
    readonly backend: WireBackendInterface;
    readonly item: WireKanbanItem;
}

const WireKanbanCard: React.VFC<CardProps> = p => {
    const { backend, item } = p;
    const { canBeDragged } = item;
    const canBeClicked = item.onTap !== undefined;

    const [newCardMode, setNewCardMode] = React.useState(false);

    const itemRef = React.useRef(item);
    itemRef.current = item;
    React.useLayoutEffect(() => {
        const i = itemRef.current;
        if (i.title?.onChangeToken === undefined && i.subtitle?.onChangeToken === undefined) return;
        const newCardForTitleViable = i.title?.onChangeToken === undefined || isEmptyOrUndefinedish(i.title?.value);
        const newCardForSubtitleViable =
            i.subtitle?.onChangeToken === undefined || isEmptyOrUndefinedish(i.subtitle?.value);
        if (newCardForTitleViable && newCardForSubtitleViable) {
            setNewCardMode(true);
        }
    }, []);

    const [focused, setFocused] = React.useState(false);

    const commitNewCardEdits = React.useCallback(() => {
        setNewCardMode(false);
        runActionAndHandleURL(item.commitAction, backend);
    }, [backend, item.commitAction]);

    const focusedRef = React.useRef(focused);
    focusedRef.current = focused;
    const timerRef = React.useRef(0);
    React.useEffect(() => {
        if (timerRef.current !== 0) return;
        timerRef.current = window.setTimeout(() => {
            const i = itemRef.current;
            if (
                focusedRef.current === false &&
                ((i.title?.onChangeToken && !isEmptyOrUndefinedish(i.title?.value)) ||
                    (i.subtitle?.onChangeToken && !isEmptyOrUndefinedish(i.subtitle?.value)))
            ) {
                commitNewCardEdits();
            }
            timerRef.current = 0;
        }, 10);
    }, [focused, commitNewCardEdits]);

    const keyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            const i = itemRef.current;
            if ((e.key === "Enter" && !e.shiftKey) || e.key === "Escape") {
                commitNewCardEdits();
                const newCardForTitleViable =
                    i.title?.onChangeToken === undefined || isEmptyOrUndefinedish(i.title?.value);
                const newCardForSubtitleViable =
                    i.subtitle?.onChangeToken === undefined || isEmptyOrUndefinedish(i.subtitle?.value);
                if (
                    e.key === "Escape" &&
                    newCardForTitleViable &&
                    newCardForSubtitleViable &&
                    i.deleteAction !== undefined
                ) {
                    runActionAndHandleURL(i.deleteAction, backend);
                }
            }
        },
        [backend, commitNewCardEdits]
    );

    React.useEffect(() => {
        if (
            focusedRef.current === false &&
            ((item.title?.onChangeToken && !isEmptyOrUndefinedish(item.title?.value)) ||
                (item.subtitle?.onChangeToken && !isEmptyOrUndefinedish(item.subtitle?.value)))
        ) {
            commitNewCardEdits();
        }
    }, [commitNewCardEdits, item.subtitle, item.title]);

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    React.useEffect(() => {
        if (item.autoFocus) {
            inputRef.current?.focus();
        }
    }, [item.autoFocus]);

    const outerTitleValue = item.title?.value ?? "";
    const [innerTitleValue, setInnerTitleValue] = React.useState(outerTitleValue);

    const onTitleChange = (newValue: string) => {
        setInnerTitleValue(newValue);
        if (item.title?.onChangeToken === undefined) return;
        backend.valueChanged(item.title.onChangeToken, newValue, ValueChangeSource.User);
    };

    React.useEffect(() => {
        setInnerTitleValue(outerTitleValue);
    }, [outerTitleValue]);

    const outerDescriptionValue = item.subtitle?.value ?? "";
    const [innerDescriptionValue, setInnerDescriptionValue] = React.useState(outerDescriptionValue);

    const onDescriptionChange = (newValue: string) => {
        setInnerDescriptionValue(newValue);
        if (item.subtitle?.onChangeToken === undefined) return;
        backend.valueChanged(item.subtitle.onChangeToken, newValue, ValueChangeSource.User);
    };

    React.useEffect(() => {
        setInnerDescriptionValue(outerDescriptionValue);
    }, [outerDescriptionValue]);

    const preventDragBackContext = usePreventDragBack();

    if (newCardMode) {
        return (
            <div
                tw="[width:269px] p-4 bg-bg-front flex flex-col rounded-lg shadow-md transition overflow-hidden
                    page-hover:shadow-lg-dark all-child:(rounded-md bg-bg-front shadow-sm ring-accent focus:ring-2)">
                {item.title !== UnboundVal && item.title.onChangeToken !== undefined && (
                    <input
                        autoFocus={item.autoFocus}
                        tw="mb-2 px-2.5 h-8"
                        ref={inputRef}
                        placeholder={getLocalizedString("titleElips", backend.appKind)}
                        value={innerTitleValue}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onKeyDown={keyDown}
                        onChange={e => onTitleChange(e.target.value)}
                    />
                )}
                {item.subtitle !== UnboundVal && item.subtitle.onChangeToken !== undefined && (
                    <textarea
                        autoFocus={item.title === UnboundVal && item.autoFocus}
                        tw="h-16 px-2.5 py-1 resize-none"
                        placeholder={getLocalizedString("descriptionElips", backend.appKind)}
                        value={innerDescriptionValue}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onKeyDown={keyDown}
                        onChange={e => onDescriptionChange(e.target.value)}
                    />
                )}
            </div>
        );
    }

    const preventDragBack = () => {
        preventDragBackContext?.setIsPrevented(true);
    };

    const allowDragBack = () => {
        preventDragBackContext?.setIsPrevented(false);
    };

    return (
        <div
            onMouseDown={preventDragBack}
            onMouseUp={allowDragBack}
            onMouseLeave={allowDragBack}
            className={classnames("group collection-item", canBeDragged ? "draggable" : "not-draggable", {
                clickable: canBeClicked,
            })}
            tw="relative [width:269px] p-4 flex bg-bg-front rounded-md shadow-md transition
                [&.draggable]:page-hover:shadow-lg-dark
                [&.not-draggable.clickable]:cursor-pointer [&.not-draggable.clickable]:page-hover:(shadow-md-dark bg-bg-hovered)"
            onClick={e => {
                e.stopPropagation();
                runActionAndHandleURL(item.onTap, backend);
            }}>
            {isDefined(item.image) && (
                <Img
                    tw="w-10 h-10 rounded-md mr-3 object-cover"
                    src={massageImageUrl(
                        item.image ?? undefined,
                        {
                            thumbnail: true,
                        },
                        backend.appID
                    )}
                />
            )}
            {item.menuActions.some(x => x.action !== undefined) && (
                <WireMenuButton
                    tw="opacity-0 group-page-hover:opacity-100 no-hover:opacity-100 text-icon-pale!
                        page-hover:text-icon-base! transition self-start absolute top-2 right-2"
                    appearance={UIButtonAppearance.Bordered}
                    size="sm"
                    menuItems={extractActions(item.menuActions, backend).filter(isDefined)}
                />
            )}
            <div tw="flex flex-col flex-grow">
                <WireKanbanCardEditor
                    isTitle={true}
                    item={item.title}
                    value={innerTitleValue}
                    onItemChange={onTitleChange}
                />
                <WireKanbanCardEditor
                    isTitle={false}
                    item={item.subtitle}
                    value={innerDescriptionValue}
                    onItemChange={onDescriptionChange}
                />
                {(item.tags?.length ?? 0) > 0 && (
                    <div tw="flex flex-wrap mt-1">
                        {item.tags?.map((t, i) => (
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                    runActionAndHandleURL(t.action, backend);
                                }}
                                key={i}
                                className={classnames(t.isSelected ? "selected" : "unselected")}
                                css={css`
                                    &.selected {
                                        ${tw`text-text-accent`}
                                    }

                                    &.unselected {
                                        ${tw`text-text-pale`}
                                    }
                                `}
                                tw="opacity-75 page-hover:opacity-100 transition truncate [max-width:150px]
                                    font-size[11px] cursor-pointer line-height[16px] px-2 mr-1 mb-1 py-0.5 relative">
                                <div
                                    css={css`
                                        .selected & {
                                            ${tw`border border-accent`}
                                        }
                                    `}
                                    tw="w-full rounded-full absolute inset-0 overflow-hidden">
                                    <div
                                        css={css`
                                            .selected & {
                                                ${tw`bg-accent`}
                                            }

                                            .unselected & {
                                                ${tw`bg-n400`}
                                            }
                                        `}
                                        tw="w-full h-full opacity-20"
                                    />
                                </div>
                                <span tw="relative">{t.name}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export const WireKanban: WireRenderer<WireKanbanComponent, { isFirstComponent: boolean }> = p => {
    const { columns, backend, newLastIndex, titleStyle, isFirstComponent, searchBar, canMoveItems } = p;

    const columnsOverrideRef = React.useRef<{
        original: readonly WireKanbanColumn[];
        sorted: readonly WireKanbanColumn[];
    }>();

    const forceUpdate = useForceUpdate();

    const onDragEnd = React.useCallback(
        (dr: DropResult) => {
            // do nothing
            if (dr.destination === undefined || dr.destination === null) return;

            let sourceColIndex = 0;
            let destColIndex = 0;
            try {
                sourceColIndex = Number.parseInt(dr.source.droppableId);
                destColIndex = Number.parseInt(dr.destination?.droppableId);
            } catch {
                return;
            }
            const sourceCol = columns[sourceColIndex];
            const sourceIndex = dr.source.index;
            const sourceCard = sourceCol.items[sourceIndex];

            const destCol = columns[destColIndex];
            let destIndex = dr.destination?.index;
            if (sourceColIndex === destColIndex && sourceIndex <= destIndex) {
                destIndex += 1;
            }
            const destCard = destCol.items[destIndex];

            if (sourceCard.categoryToken !== undefined) {
                backend.valueChanged(sourceCard.categoryToken, destCol.category, ValueChangeSource.User);
            }
            if (sourceCard.indexToken !== undefined) {
                backend.valueChanged(
                    sourceCard.indexToken,
                    destCard?.insertBeforeIndex ?? newLastIndex,
                    ValueChangeSource.User
                );
            }

            if (sourceCard.onDrag !== undefined) {
                runActionAndHandleURL(sourceCard.onDrag, backend);
            }

            const sorted = [...columns];
            const newSourceItems = [...sourceCol.items];
            const toInsert = newSourceItems.splice(sourceIndex, 1);
            sorted[sourceColIndex] = {
                ...sourceCol,
                items: newSourceItems,
            };

            const newDestCol = {
                ...sorted[destColIndex],
                items: [...sorted[destColIndex].items],
            };
            newDestCol.items.splice(dr.destination?.index, 0, ...toInsert);
            sorted[destColIndex] = newDestCol;

            columnsOverrideRef.current = {
                original: columns,
                sorted,
            };
            forceUpdate();
        },
        [backend, columns, newLastIndex, forceUpdate]
    );

    if (columnsOverrideRef.current !== undefined && columnsOverrideRef.current.original !== columns) {
        columnsOverrideRef.current = undefined;
    }

    const useCols = columnsOverrideRef.current?.sorted ?? columns;

    const filterArgs = useDynamicFilter(p.dynamicFilter, backend);
    const multipleFilterProps = useMultipleDynamicFilters(p.multipleDynamicFilters, backend);

    const getContainerForClone = () => {
        const element = document.getElementById(APP_MODAL_ROOT);
        if (element === null) {
            panic("We're missing the APP_MODAL_ROOT. This should never happen.");
        }

        return element;
    };

    const { searchValue, onSearchChange } = useSearchBar(searchBar, backend, undefined);

    return (
        <WireListContainer
            title={p.title ?? ""}
            titleStyle={titleStyle}
            titleActions={extractActions(p.titleActions, backend)}
            {...filterArgs}
            multipleFilterProps={multipleFilterProps}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            styleVariant={UIStyleVariant.Default}
            appKind={backend.appKind}
            isFirstComponent={isFirstComponent}>
            <div tw="flex justify-start overflow-x-auto p-0.5 text-text-base">
                <DragDropContext onDragEnd={onDragEnd}>
                    {useCols.map((col, ind) => {
                        const renderItem = getRenderItem(col.items, backend);

                        return (
                            <Droppable
                                key={ind}
                                droppableId={`${ind}`}
                                getContainerForClone={getContainerForClone}
                                renderClone={renderItem}
                                column={col}>
                                {droppableProvided => (
                                    <div
                                        {...droppableProvided.droppableProps}
                                        ref={droppableProvided.innerRef}
                                        tw="px-2 pt-12 pb-6 not-last:mr-4 bg-bg-back rounded-lg shadow-sm-soft">
                                        <h1
                                            tw="text-xs uppercase font-semibold text-text-base mb-1.5 [width:269px] -mt-10
                                            pr-1 pl-2 flex items-center">
                                            <div tw="flex-grow overflow-x-hidden [width:225px] overflow-ellipsis">
                                                {col.title}
                                            </div>
                                            {col.addItemAction !== undefined && (
                                                <button
                                                    onClick={() => runActionAndHandleURL(col.addItemAction, backend)}
                                                    tw="flex shrink-0 text-icon-pale items-center justify-center w-8
                                                    h-8 rounded-lg page-hover:bg-n300 transition">
                                                    <GlideIcon
                                                        icon="st-plus-stroke"
                                                        kind="stroke"
                                                        strokeWidth={1.5}
                                                        iconSize={20}
                                                    />
                                                </button>
                                            )}
                                        </h1>
                                        {col.items.map((item, itemIndex) => (
                                            <Draggable
                                                key={item.key}
                                                index={itemIndex}
                                                draggableId={item.key}
                                                isDragDisabled={item.indexToken === undefined || !canMoveItems}
                                                item={item}
                                                backend={backend}>
                                                {renderItem}
                                            </Draggable>
                                        ))}
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        );
                    })}
                </DragDropContext>
            </div>
        </WireListContainer>
    );
};

/**
 * Got this directly from https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
 */
const getRenderItem =
    (items: readonly WireKanbanItem[], backend: WireBackendInterface): DraggableChildrenFn =>
    (draggableProvided, _snapshot, rubric) => {
        const item = items[rubric.source.index];

        return (
            <div
                tw="pb-2"
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                ref={draggableProvided.innerRef}>
                <WireKanbanCard backend={backend} item={item} />
            </div>
        );
    };

const [LazyDragDropContext, dragDropContextSequencer] = lazyLoadWithSequence(() => import("./lazy-drag-drop-context"));

interface DragDropContextProps {
    readonly onDragEnd: (dr: DropResult) => void;
    readonly children: React.ReactNode;
}

const DragDropContext: React.FC<DragDropContextProps> = p => {
    const { onDragEnd, children } = p;

    const childElement = <>{children}</>;

    return (
        <React.Suspense fallback={childElement}>
            <LazyDragDropContext onDragEnd={onDragEnd}>{childElement}</LazyDragDropContext>
        </React.Suspense>
    );
};

const [LazyDroppable, droppableSequencer] = lazyLoadWithSequence(
    () => import("./lazy-droppable"),
    dragDropContextSequencer
);

interface DroppableProps {
    readonly droppableId: string;
    readonly getContainerForClone: () => HTMLElement;
    readonly renderClone: DraggableChildrenFn;
    readonly column: WireKanbanColumn;
    readonly children: (provided: DroppableProvided) => React.ReactElement<HTMLElement>;
}

const Droppable: React.FC<DroppableProps> = p => {
    const { droppableId, getContainerForClone, renderClone, column, children } = p;

    const dummyProvidedProps: DroppableProvided = {
        innerRef: ignore,
        droppableProps: {
            "data-rbd-droppable-context-id": "dummy-context-id",
            "data-rbd-droppable-id": "dummy-id",
        },
        placeholder: null,
    };

    const fallback = children(dummyProvidedProps);

    return (
        <React.Suspense fallback={fallback}>
            <LazyDroppable
                droppableId={droppableId}
                getContainerForClone={getContainerForClone}
                renderClone={renderClone}
                column={column}>
                {children}
            </LazyDroppable>
        </React.Suspense>
    );
};

const [LazyDraggable] = lazyLoadWithSequence(() => import("./lazy-draggable"), droppableSequencer);

interface DraggableProps {
    readonly index: number;
    readonly draggableId: string;
    readonly isDragDisabled: boolean;
    readonly children: DraggableChildrenFn;
    readonly item: WireKanbanItem;
    readonly backend: WireBackendInterface;
}

const Draggable: React.FC<DraggableProps> = p => {
    const { index, draggableId, isDragDisabled, children, item, backend } = p;

    const fallback = (
        <div tw="pb-2">
            <WireKanbanCard backend={backend} item={item} />
        </div>
    );

    return (
        <React.Suspense fallback={fallback}>
            <LazyDraggable index={index} draggableId={draggableId} isDragDisabled={isDragDisabled}>
                {children}
            </LazyDraggable>
        </React.Suspense>
    );
};
